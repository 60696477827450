export const MenuRoutes = [
    {
        title:'Home',
        path:'/'
    }, 
    {
        title:'Collections',
        path:'/collectionsv2' 
    },
    // {
    //     title:'Create',
    //     path:'/createcolletionv2' 
    // },
    {
        title:'Memberships',
        path:'/membershipspagev2' 
    }
]
