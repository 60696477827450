import { initializeConnector } from '@web3-react/core';
import { Actions } from '@web3-react/types';
import { MetaMask } from '@web3-react/metamask';
import { CoinbaseWallet } from '@web3-react/coinbase-wallet';
import { WalletConnect } from '@web3-react/walletconnect';
import { NETWORKS } from '../constants/chain';

const rpcs: { [key: number]: string } = {}

for (const key in NETWORKS) {
  if (NETWORKS[key].providerRpcUrl) {
    rpcs[key] = NETWORKS[key].providerRpcUrl as string
  }
}
export const [metaMask, metaMaskHooks] = initializeConnector<MetaMask>((actions:Actions ) => new MetaMask({ actions }));

export const [walletConnect, walletConnectHooks] = initializeConnector<WalletConnect>(
  (actions) =>
    new WalletConnect({
      actions,
      options: {
        rpc: rpcs,
      },
    })
)

export const [coinbaseWallet, coinbaseWalletHooks] = initializeConnector<CoinbaseWallet>(
  (actions) =>
    new CoinbaseWallet({
      actions,
      options: {
        url: rpcs[1][0],
        appName: 'web3-react'
      },
    })
)
