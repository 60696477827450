export default {
    // apiUrl: 'https://ZenobitCulture.herokuapp.com',
    apiUrl: 'http://localhost:5000',
    // apiUrl: 'https://api.ZenobitCulture.com',
    // apiUrlMain: 'https://ZenobitCulture.herokuapp.com',
    frontendUrl: 'http://localhost:3000',
    programID: "4DyzEPyjH5SCLnCw9ETdfjAS9m4vfnz4ufTg7WtA16Q3",
    avaxChainId: "43113",
    REACT_APP_PINATA_API_KEY: "86fb24ee7a5e8f995233",
    REACT_APP_PINATA_API_SECRET: "1036c2ad2527608b43c070f7532a11cfafe4980481291c701849f46e29f7030f",
    REACT_APP_PINATA_API_JWT: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiJkYjE2OTU1ZS1hZjJkLTQ3ODMtYTJmNS01ZTM0NDJjMjIzMDkiLCJlbWFpbCI6InByb25pbmtpcmlsbGwyMjhAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siaWQiOiJGUkExIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6Ijg2ZmIyNGVlN2E1ZThmOTk1MjMzIiwic2NvcGVkS2V5U2VjcmV0IjoiMTAzNmMyYWQyNTI3NjA4YjQzYzA3MGY3NTMyYTExY2ZhZmU0OTgwNDgxMjkxYzcwMTg0OWY0NmUyOWY3MDMwZiIsImlhdCI6MTY1ODkwOTk2NH0.U3OP3wmAsLXjPUWW9OBnWcXQb13pTQFfXu2f8gLno-g"
};
