import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Stack,
  Typography,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useSwitchNetworkMutation } from '../../hooks/blockchain';
import { getChainName } from '../../utils/blockchain';
import { AppDialogTitle } from '../AppDialogTitle';
import { useWeb3React } from '@web3-react/core';
import { Connector } from '@web3-react/types';

interface Props {
  dialogProps: DialogProps; 
  chainId?: number;
}

export function SwitchNetworkDialog({ dialogProps,chainId }: Props) {
  const { onClose } = dialogProps; 
  const switchNetworkMutation = useSwitchNetworkMutation();

  const handleClose = () => onClose!({}, 'backdropClick');

  const handleSwitchNetwork = async () => {
    // console.log('SwitchNetworkDialog chainId:',chainId) 
    // console.log('SwitchNetworkDialog chainId !== undefined:',chainId !== undefined) 
    if (chainId !== undefined) {
      await switchNetworkMutation.mutateAsync({chainId:Number(chainId)}); 
      handleClose();
    }
  };

  const handleReset = () => {
    switchNetworkMutation.reset();
  };

  return (
    <Dialog {...dialogProps}>
      <AppDialogTitle
        title={
          <FormattedMessage
            id="switch.network"
            defaultMessage="Switch network"
            description="Switch network dialog title"
          />
        }
        onClose={handleClose}
      />
      <DialogContent dividers>
        <Stack spacing={2}>
        <Alert variant="filled" severity="warning" >
          <Typography variant="body1">
          
            Please, switch to {getChainName(chainId)} network to create/listings/offers/buy assets
          </Typography>
       </Alert>
          {switchNetworkMutation.isError && (
            <Alert severity="error" onClose={handleReset}>
              {switchNetworkMutation.error?.message}
            </Alert>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={switchNetworkMutation.isLoading}
          startIcon={
            switchNetworkMutation.isLoading ? (
              <CircularProgress color="inherit" size="1rem" />
            ) : undefined
          }
          onClick={handleSwitchNetwork}
        >
          <FormattedMessage
            id="switch"
            defaultMessage="Switch"
            description="switch"
          />
        </Button>
        <Button
          disabled={switchNetworkMutation.isLoading}
          onClick={handleClose}
        >
          <FormattedMessage
            id="cancel"
            defaultMessage="Cancel"
            description="Cancel"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
