import { FC, ReactNode,useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Outlet, useLocation } from 'react-router-dom';  
import { Box,Stack, alpha, lighten, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import Footer from './Footer';
import Header from './Header';
import MobileDrawer from './MobileDrawer'; 
import Page from '../../components/Page';
import { GradientStyle } from '../../components/StyledComponents';
//import Page from '../../components/Page';

// ----------------------------------------------------------------------
// export const CardStyle = styled('div')(({theme, sx }) => ({
//     boxShadow: (theme.palette.mode==='light'?`0px 3px 26px rgb(16 30 115 / 10%)`:`0px 2px 16px rgb(255 255 255 / 10%)`),
//     borderRadius: `10px`,
//     display: 'flex',
//     justifyContent: 'center',
//     background:theme.palette.mode==='light'?'white':theme.palette.background.paper,
//     ...sx,
//   }));
//   export const GradientStyle = styled('div')(({ theme, sx }) => ({
  
//     ...sx,
//     height: '100%',
//     background: theme.palette.mode === 'light' ? `-moz-linear-gradient(rgba(242, 156, 67, 0.26) 0%, rgba(229, 239, 255, 0.263) 83.7%, rgba(229, 239, 255, 0) 100%)`: `-moz-linear-gradient(rgba(0,18,14, 0.8) 0%, rgba(0,28,44, 0.8) 83.7%, rgba(229, 239, 255, 0) 100%)`,
//     background: theme.palette.mode === 'light' ? `-ms-linear-gradient(rgba(242, 156, 67, 0.26) 0%, rgba(229, 239, 255, 0.263) 83.7%, rgba(229, 239, 255, 0) 100%)` : '-ms-linear-gradient(rgba(0,18,14, 0.8) 0%, rgba(0,28,44, 0.8) 83.7%, rgba(229, 239, 255, 0) 100%)',
//     background: theme.palette.mode === 'light' ? `-webkit-gradient(rgba(242, 156, 67, 0.26) 0%, rgba(229, 239, 255, 0.263) 83.7%, rgba(229, 239, 255, 0) 100%)` : '-webkit-gradient(rgba(0,18,14, 0.8) 0%, rgba(0,28,44, 0.8) 83.7%, rgba(229, 239, 255, 0) 100%)',
//     background: theme.palette.mode === 'light' ? `-webkit-linear-gradient(rgba(242, 156, 67, 0.26) 0%, rgba(229, 239, 255, 0.263) 83.7%, rgba(229, 239, 255, 0) 100%)` : '-webkit-linear-gradient(rgba(0,18,14, 0.8) 0%, rgba(0,28,44, 0.8) 83.7%, rgba(229, 239, 255, 0) 100%)',
//     background: theme.palette.mode === 'light' ? `-o-linear-gradient(rgba(242, 156, 67, 0.26) 0%, rgba(229, 239, 255, 0.263) 83.7%, rgba(229, 239, 255, 0) 100%)` : '-o-linear-gradient(rgba(0,18,14, 0.8) 0%, rgba(0,28,44, 0.8) 83.7%, rgba(229, 239, 255, 0) 100%)',
//     background: theme.palette.mode === 'light' ? `linear-gradient(rgba(242, 156, 67, 0.26) 0%, rgba(229, 239, 255, 0.263) 83.7%, rgba(229, 239, 255, 0) 100%)` : 'linear-gradient(rgba(0,18,14, 0.8) 0%, rgba(0,28,44, 0.8) 83.7%, rgba(229, 239, 255, 0) 100%)',
  
//   }));

  interface MainLayoutProps {
    children?: ReactNode;
  }
  const MainLayout: FC<MainLayoutProps> = ({ children }) => { 
    const theme = useTheme();
    return (
      <>
       <Stack sx={{ minHeight: 1 }}>
            <Header />
            <Box sx={{ minHeight: '100vh' }}> 
              <GradientStyle sx={{ paddingTop: { xs: 10, sm: 10 }, paddingBottom: 4, display: 'flex', gap: 8, flexDirection: 'column' }} >
              {children || <Outlet />}
              </GradientStyle>
          
            </Box>
            <Box sx={{ flexGrow: 1 }} />

            <Footer />

        </Stack>
    </>
    );
}

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
