export enum SwapSteps {
  Start,
  StartApproval,
  WaitingWalletApproval,
  FinishApproval,
  StartSwap,
  WaitingWalletSwap,
  FinishSwap,
}

export enum TraderOrderStatus {
  Open = 'open',
  Filled = 'filled',
  Expired = 'expired',
  Cancelled = 'cancelled',
  All = 'all',
}

export enum TraderOrderVisibility {
  Public = 'public',
  Private = 'private',
}

export enum NetworkName {
  ETH = 'eth',
  BASE = 'base',
  POLYGON = 'polygon',
  SEPOLIA = 'sepolia',
  BASESEPOLIA = 'basesepolia',
  OPTIMISMSEPOLIA = 'optimismsepolia',
  ARBITRUMSEPOLIA = 'arbitrumsepolia',
  ARBITRUM = 'arbitrum',
  MUMBAI = 'amoy',
  OPTMISM = 'optimism'
}

export enum ChainId {
  ETH = 1,
  Base = 8453, 
  Basesepolia = 84532,
  Polygon = 137, 
  Sepolia = 11155111,
  Amoy = 80002,
  Optimism = 10,
  Optimismsepolia = 11155420,
  Arbitrum = 42161,
  Arbitrumsepolia = 421614,
}

export enum NFTType {
  ERC1155 = 'ERC1155',
  ERC721 = 'ERC721',
}

export enum SellOrBuy {
  All = 'all',
  Sell = 'sell',
  Buy = 'buy',
}
