import type { AddEthereumChainParameter } from '@web3-react/types';
import { ChainId } from './enum';

const infuraKey = process.env.INFURA_API_KEY;
export const expectedChainId = 80002;

export const mcontractAddresses : {
  [chainId: number]:any 
} =  {
 [ChainId.Polygon]:{
   colletions:[
    '0xb9f382d0b8b7345f2f0d2f7510ee121dc75bee03',
    '0x69cE2CAE837a7F8B01Cb66db560f31E2ae3A51aE',
    '0x939821Fd096b4e4F67f369AF67cf9411b1A28160',
    '0xD5cA946AC1c1F24Eb26dae9e1A53ba6a02bd97Fe',
    '0xD27029e4EBc3c4C55fCfAdDDC54fa0B911829AFC',
    '0x9E8Ea82e76262E957D4cC24e04857A34B0D8f062',
    '0x83a5564378839EeF0721bc68A0fbeb92e2dE73d2',
    '0x2953399124F0cBB46d2CbACD8A89cF0599974963',
    '0x080B41b35b47587A85383aDfaB2449ba145eb195',
    '0x4d544035500D7aC1B42329c70eb58E77f8249f0F',
    '0x22d5f9B75c524Fec1D6619787e582644CD4D7422',
    '0xa54999a6C1380164eccd4727f5E7a5bfe2470D44',
    '0x473989BF6409D21f8A7Fdd7133a40F9251cC1839'
  ]},
 [ChainId.Sepolia]:{
  colletions:[
  '0x4355e3d8297B5e1dcb9CE0CB691Da5B5713EE9B5',
  '0x085Ef82658B7039A998D656E76c98e673B32F63B',
  '0x91164C6e24CCbDFB82141Ca9EeC4EDaaE22d2114',
  '0x8B2C167C808868C87C60b559b65866b82db2ef8e',
  '0x088e416a49DCa096fe9d855009BF064ddf724093'
 ]},
 [ChainId.Amoy]:{
  colletions:[ 
  '0xEa072EB2c7FBC875DcB1B58F240fAF8755399f7e',
  '0x81cfBE7B73b27bfe95ba2c44Ef373AAFac6dBa3a',
  '0x4E360cF41d53e65c424c9A62f44A7B50eB8e9bC5',
  '0xfF1C6ba9eDF1625b2c72E784f173C06722CbBB0c'
 ]},
 [ChainId.Optimismsepolia]:{
  colletions:[
  '0x03fD3aE2758aB37E8692e1844e0692e9B058C735',
  '0x2D94Ce9671B20bfB97071e077EB614829641eDdf',
  '0xae1c06bb4c68391e6775eea195f1ae34c9d7f947',
  '0x53584e5e0e96331cedbbf1cc11e667d2926685f6'
 ]} 
};

const ETH: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Ether',
  symbol: 'ETH',
  decimals: 18,
};

const MATIC: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Matic',
  symbol: 'MATIC',
  decimals: 18,
};

interface BasicChainInformation {
  urls: string[];
  name: string;
}

interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter['nativeCurrency'];
  blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls'];
}

function isExtendedChainInformation(
  chainInformation: BasicChainInformation | ExtendedChainInformation
): chainInformation is ExtendedChainInformation {
  return !!(chainInformation as ExtendedChainInformation).nativeCurrency;
}

export function getAddChainParameters(
  chainId: number
): AddEthereumChainParameter | number {
  const chainInformation = CHAINS[chainId];
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls as string[],
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    };
  } else {
    return chainId;
  }
}

export const CHAINS: {
  [chainId: number]: BasicChainInformation | ExtendedChainInformation;
} = {
  [ChainId.ETH]: {
    urls: [
      infuraKey ? `https://mainnet.infura.io/v3/${infuraKey}` : undefined,
      process.env.alchemyKey
        ? `https://eth-mainnet.alchemyapi.io/v2/${process.env.alchemyKey}`
        : undefined,
      'https://cloudflare-eth.com',
    ].filter((url) => url !== undefined) as string[],
    name: 'Mainnet',
  },
  [ChainId.Sepolia]: {
    urls: [
      infuraKey ? `https://Sepolia.infura.io/v3/${infuraKey}` : undefined,
    ].filter((url) => url !== undefined) as string[],
    name: 'Sepolia',
  },
  [ChainId.Base]: {
    urls: [
      infuraKey ? `https://rinkeby.infura.io/v3/${infuraKey}` : undefined,
    ].filter((url) => url !== undefined) as string[],
    name: 'Rinkeby',
  },
  5: {
    urls: [
      infuraKey ? `https://goerli.infura.io/v3/${infuraKey}` : undefined,
    ].filter((url) => url !== undefined) as string[],
    name: 'Görli',
  },
  42: {
    urls: [
      infuraKey ? `https://kovan.infura.io/v3/${infuraKey}` : undefined,
    ].filter((url) => url !== undefined) as string[],
    name: 'Kovan',
  },
  // Optimism
  [ChainId.Optimism]: {
    urls: [
      infuraKey
        ? `https://optimism-mainnet.infura.io/v3/${infuraKey}`
        : undefined,
      'https://mainnet.optimism.io',
    ].filter((url) => url !== undefined) as string[],
    name: 'Optimism',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://optimistic.etherscan.io'],
  },
  69: {
    urls: [
      infuraKey
        ? `https://optimism-kovan.infura.io/v3/${infuraKey}`
        : undefined,
      'https://kovan.optimism.io',
    ].filter((url) => url !== undefined) as string[],
    name: 'Optimism Kovan',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://kovan-optimistic.etherscan.io'],
  },
  // Arbitrum
  [ChainId.Arbitrum]: {
    urls: [
      infuraKey
        ? `https://arbitrum-mainnet.infura.io/v3/${infuraKey}`
        : undefined,
      'https://arb1.arbitrum.io/rpc',
    ].filter((url) => url !== undefined) as string[],
    name: 'Arbitrum One',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://arbiscan.io'],
  },
  421611: {
    urls: [
      infuraKey
        ? `https://arbitrum-rinkeby.infura.io/v3/${infuraKey}`
        : undefined,
      'https://rinkeby.arbitrum.io/rpc',
    ].filter((url) => url !== undefined) as string[],
    name: 'Arbitrum Testnet',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://testnet.arbiscan.io'],
  },
  // Polygon
  [ChainId.Polygon]: {
    urls: [
      infuraKey
        ? `https://polygon-mainnet.infura.io/v3/${infuraKey}`
        : undefined,
      'https://polygon-rpc.com',
    ].filter((url) => url !== undefined) as string[],
    name: 'Polygon Mainnet',
    nativeCurrency: MATIC,
    blockExplorerUrls: ['https://polygonscan.com'],
  },
  80002: {
    urls: [
      infuraKey
        ? `https://polygon-amoy.infura.io/v3/${infuraKey}`
        : undefined,
    ].filter((url) => url !== undefined) as string[],
    name: 'Polygon Amoy',
    nativeCurrency: MATIC,
    blockExplorerUrls: ['https://amoy.polygonscan.com'],
  },
};

export const URLS: { [chainId: number]: string[] } = Object.keys(
  CHAINS
).reduce<{ [chainId: number]: string[] }>((accumulator, chainId) => {
  const validURLs: string[] = CHAINS[Number(chainId)].urls;

  if (validURLs.length) {
    accumulator[Number(chainId)] = validURLs;
  }

  return accumulator;
}, {});
