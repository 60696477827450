import { Interface } from "@ethersproject/abi";
import { ERC20Abi } from "../constants/abis";
import { getMulticallFromProvider } from "./multical";
import { CallInput } from "@indexed-finance/multicall"; 
import { ethers } from "ethers";
 
export async function getErc20Tokeninfo(chainId:number,tokenAddress: string,provider?:ethers.providers.JsonRpcProvider) { 
  //const {provider,chainId:newchainid} = useWeb3React(); 
        
        const multicall = await getMulticallFromProvider(provider);
        const iface = new Interface(ERC20Abi);
        let calls: CallInput[] = []; 
        calls.push({
            interface: iface,
            target: tokenAddress,
            function: 'name',
        }); 
        calls.push({
            interface: iface,
            target: tokenAddress,
            function: 'symbol',
        });
        calls.push({
            interface: iface,
            target: tokenAddress,
            function: 'decimals', 
        }); 

        const response = await multicall?.multiCall(calls);

            if (response) {
                console.log("response:",response);
                const [, results] = response;  
                const name = results[0];
                const symbol = results[1];
                const decimals = results[2]; 

                return {
                    decimals,
                    name,
                    symbol,
                };
            }else{
               return 
            }  
  }