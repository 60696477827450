import {artTokenContract, marketplaceContract, coinContract} from './contracts';
import {artTokenContractamoy, marketplaceContractamoy, coinContractamoy} from './contracts-amoy';
import {ethers} from "ethers";

const devnet = `https://avalanche-fuji.infura.io/v3/e0ee07907fbb43608c8f0e1cc1f2eed2`;
const provider = new ethers.providers.JsonRpcProvider(devnet);
const ArtTokenContract = new ethers.Contract(artTokenContract.fuji, artTokenContract.abi, provider);
const MarketplaceContract = new ethers.Contract(marketplaceContract.fuji, marketplaceContract.abi, provider);
const CoinContract = new ethers.Contract(coinContract.fuji, coinContract.abi, provider);

const devnetamoy = `https://rpc.ankr.com/polygon_amoy`;
const provideramoy = new ethers.providers.JsonRpcProvider(devnetamoy);
const ArtTokenContractamoy = new ethers.Contract(artTokenContractamoy.amoy, artTokenContractamoy.abi, provideramoy);
const MarketplaceContractamoy = new ethers.Contract(marketplaceContractamoy.amoy, marketplaceContractamoy.abi, provideramoy);
const CoinContractamoy = new ethers.Contract(coinContractamoy.amoy, coinContractamoy.abi, provideramoy);

export {ArtTokenContract, MarketplaceContract, CoinContract};
export {ArtTokenContractamoy, MarketplaceContractamoy, CoinContractamoy};
