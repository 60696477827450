export const ERC721Abi = [
  'function name() external view returns (string _name)',
  'function symbol() external view returns (string _symbol)',
  'function ownerOf(uint256 _tokenId) view returns (address)',
  'function balanceOf(address _owner) view returns (uint256 balance)',
  'function tokenURI(uint256 _tokenId) view returns (string)',
  'function contractURI() view returns (string)', 
];

export const ERC1155Abi = [
  'function name() external view returns (string _name)',
  'function symbol() external view returns (string _symbol)',
  'function uri(uint256 _tokenId) view returns (string)',
  'function balanceOf(address _account, uint256 _id) public view returns (uint256 balance)',
  'function contractURI() view returns (string)', 
];

export const ERC20Abi = [
  'function name() public view returns (string)',
  'function symbol() public view returns (string)',
  'function decimals() public view returns (uint8)',
  'function totalSupply() public view returns (uint256)',
  'function balanceOf(address _owner) public view returns (uint256 balance)',
  'function transfer(address _to, uint256 _value) public returns (bool success)',
  'function transferFrom(address _from, address _to, uint256 _value) public returns (bool success)',
  'function approve(address _spender, uint256 _value) public returns (bool success)',
  'function allowance(address _owner, address _spender) public view returns (uint256 remaining)',
  'event Transfer(address indexed _from, address indexed _to, uint256 _value)',
  'event Approval(address indexed _owner, address indexed _spender, uint256 _value)',
];

export const WETHAbi = [
  ...ERC20Abi,
  'function deposit() public payable',
  'function withdraw(uint wad) public',
]; 

  
/*     event MintTo(
      address collectionAddr,
      address _to,
      string _uri,
      uint256 _quantity,
      bytes data,
      uint256 tokenId
  ); */

export const MARKETAbi = [ 
  'function mintTo(address collectionAddr, address _to, string _uri,uint256 _quantity) public',
  'event MintTo(address collectionAddr, address _to, string _uri, uint256 _quantity, bytes data, uint256 tokenId)',
];
