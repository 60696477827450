import {  Routes, Route, HashRouter } from 'react-router-dom'; 
import AppProviders from './AppProviders';
import Router from './routes'; 
import ScrollToTop from './components/ScrollToTop';
function App() {
  return (
    <AppProviders> 
       <HashRouter basename='/'> 
       <ScrollToTop />
         <Router />
      </HashRouter>
    </AppProviders>
  );
}

export default App;
