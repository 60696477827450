// highlight
import './utils/highlight'; 
// scroll bar
import 'simplebar/src/simplebar.css'; 
// lightbox
import 'react-image-lightbox/style.css';  
// editor
import 'react-quill/dist/quill.snow.css'; 
// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'; 
// swiper 
import './swiper.min.css'; 
// lazy image
import 'react-jinke-music-player/assets/index.css'
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import React from 'react';
import ReactDOM from 'react-dom/client'; 
import { Buffer } from 'buffer';
import './index.css';
import App from './App';

window.Buffer = window.Buffer || require('buffer').Buffer;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render( 
    <App /> 
);
