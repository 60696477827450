import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
 
type StepperProps = {
    steps?: any ,
    activeStep?:number, 
    iserror?:boolean, 
    isSuccess?:boolean, 
  };
export default function HorizontalStepperWithError({steps,activeStep,iserror,isSuccess}:StepperProps) {
   
  const isStepFailed = (step: number) => {
    return step ===activeStep&& iserror;
  };
  const isStepcompleted = (step: number) => {
    return step === steps.length-1&& isSuccess;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label:any, index:number) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
            error?: boolean;
          } = {};
          if (isStepFailed(index)) {
            labelProps.optional = (
              <Typography variant="caption" color="error">
                error
              </Typography>
            );
            labelProps.error = true;
          }
          if (isStepcompleted(index)) {
            stepProps.completed = true;
          }

          return (
            <Step key={label}  {...stepProps}>
              <StepLabel {...labelProps}><Typography align="center" variant="h6">{label}</Typography></StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
